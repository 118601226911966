import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface MoneyState {
  withServiceTax: boolean,
  currency: string,
  prefix: string,
  fxRate: number,
  fxIndex: number,
  multiplier: number,
}

const initialState: MoneyState = {
  withServiceTax: false,
  currency: 'USD',
  prefix: '$',
  fxRate: 1,
  fxIndex: 0,
  multiplier: 1,
}

export const fxOptions = [
  { value: "usd", label: "$ USD" },
  { value: "gbp", label: "£ GBP" },
  { value: "eur", label: "€ EUR" },
];

const fx = {
  usd: {
    c: 'USD',
    p: '$',
    r: 1,
    i: 0,
  },
  gbp: {
    c: 'GBP',
    p: '£',
    r: 0.8084,
    i: 1,
  },
  eur: {
    c: 'EUR',
    p: '€',
    r: 0.9226,
    i: 1,
  }
}

const getMultiplier = (state: MoneyState): number => {
 return (state.fxRate || 1) * (state.withServiceTax ? 1.276 : 1);
}

export const moneySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    toggleTax: (state) => {
      state.withServiceTax = !state.withServiceTax;
      state.multiplier = getMultiplier(state);
    },
    switchFX: (state, action: PayloadAction<string>) => {
      if(fx[action.payload]){
        state.currency = fx[action.payload].c;
        state.prefix = fx[action.payload].p;
        state.fxIndex = fx[action.payload].i;
        state.fxRate = fx[action.payload].r;
        state.multiplier = getMultiplier(state);
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleTax: toggleTax, switchFX: switchFX } = moneySlice.actions

export default moneySlice.reducer