import { Link } from "gatsby";
import React from "react";
import * as styles from "../styles/layout.css";

const Footer = (props) => {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerInner}>
          <ul className={styles.footerLinks}>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
            <li>
              <Link to="/terms">Terms</Link>
            </li>
            <li>
              <Link to="/sitemap">Sitemap</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
          <div className={styles.footerCopyright}>
            © MaldivesGuide.com {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
