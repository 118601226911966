exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resorts-js": () => import("./../../../src/pages/resorts.js" /* webpackChunkName: "component---src-pages-resorts-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-atoll-template-js": () => import("./../../../src/templates/atollTemplate.js" /* webpackChunkName: "component---src-templates-atoll-template-js" */),
  "component---src-templates-resort-template-js": () => import("./../../../src/templates/resortTemplate.js" /* webpackChunkName: "component---src-templates-resort-template-js" */)
}

