import React, { createContext } from "react";
import { signal } from "@preact/signals-react";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../helpers/Firebase";

export const createUserState = () => {

  if (typeof window == 'undefined') {
    return;
  }

  const user = signal(localStorage.getItem("user"));
  const loginView = signal("");
  const loginMessage = signal("");

  onAuthStateChanged(auth, (u) => {
    if (u) {
      user.value = u;
      localStorage.setItem("user", u);
    } else {
      user.value = null;
      localStorage.removeItem("user");
    }
  });

  const refreshUser = () => {
    auth.currentUser.reload().then(() => {
      user.value = auth.currentUser;
      localStorage.setItem("user", auth.currentUser);
    });
  };

  const login = (message) =>{
    loginView.value = "signin";
    loginMessage.value = message ?? "";
  }

  const logout = () => {
    auth.signOut().then(() => {
      user.value = null;
      localStorage.removeItem("user");
    });
  }

  return { user, loginMessage, loginView, login, logout, refreshUser };
};

export const UserContext = createContext();
