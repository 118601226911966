import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";

export interface PartyState {
  nights: number;
  adults: number;
  children: number;
  childAges: number[];
  rooms: number;
  dates: (Dayjs | null)[];
}

// const initialState: PartyState = {
//   nights: 0,
//   adults: 2,
//   children: 0,
//   rooms: 1,
//   dates: [null, null],
// };

const saveState = (state) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem("party", JSON.stringify(state));
  }
};

const loadState = (): PartyState => {

  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem("party");
    if (saved) {
      return JSON.parse(saved);
    }
  }

  return {
    nights: 0,
    adults: 2,
    children: 0,
    childAges: [],
    rooms: 1,
    dates: [null, null],
  };
};

const initialState: PartyState = loadState();

export const partySlice = createSlice({
  name: "party",
  initialState,
  reducers: {
    updateNights: (state, action: PayloadAction<number>) => {
      state.nights = action.payload;
      saveState(state);
    },
    updateDays: (state, action: PayloadAction<(Dayjs | null)[]>) => {
      state.dates = action.payload;
      saveState(state);
    },

    updateAdults: (state, action: PayloadAction<number>) => {
      
      if(action.payload < 1 || action.payload > 10)
        return;

      state.adults = action.payload;
      saveState(state);
    },
    updateChildren: (state, action: PayloadAction<number>) => {
      
      if(!state.childAges)
        state.childAges = [];

      if(action.payload < 0 || action.payload > 10)
        return;

      state.children = action.payload;

      if(state.children < state.childAges.length)
        state.childAges.splice(0, state.childAges.length - state.children);
      else if(state.children > state.childAges.length)
        state.childAges = [...state.childAges, ...Array(state.children - state.childAges.length).fill(12)];
      
      saveState(state);
    },
    updateChildAge: (state, action: PayloadAction<number[]>) => {
      state.childAges[action.payload[0]] = action.payload[1];
    },

    updateRooms: (state, action: PayloadAction<number>) => {

      if(action.payload < 1 || action.payload > 10)
        return;

      state.rooms = action.payload;
      saveState(state);
    },

  },
});

// Action creators are generated for each case reducer function
export const { updateNights, updateDays, updateAdults, updateChildren, updateRooms, updateChildAge } = partySlice.actions;

export default partySlice.reducer;
