// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC08GsJ9k9EFBrCxyc8Eo9mhWJ_6z680lI",
  authDomain: "auth.maldivesguide.com",
  projectId: "maldivesguide-b5042",
  storageBucket: "maldivesguide-b5042.appspot.com",
  messagingSenderId: "244053900305",
  appId: "1:244053900305:web:ac7d00c959ade956d305b0",
  measurementId: "G-PNLLM9P05M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const functions = getFunctions(app);
export const firestore = getFirestore(app);

if (process.env.GATSBY_FIREBASE_EMULATOR === "true") {
  console.log("Using Firebase Emulator");
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

// export const analytics = getAnalytics(app);

// export const microsoftProvider = new OAuthProvider('microsoft.com');

export const voteFishFeeding = httpsCallable(functions, "addVote");
