import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import glogo from "../images/google-logo.png";

import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../helpers/Firebase";

import { useSignals } from "@preact/signals-react/runtime";
import { UserContext } from "../helpers/UserContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useForm } from "react-hook-form";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";

import logo from "../images/logo.png";
import * as styles from "../styles/login.css";

const signInGoogle = async () => {
  signInWithPopup(auth, new GoogleAuthProvider())
    .then((result) => {
      console.log("Logged In Google", result);
    })
    .catch((error) => {
      const errCode = error.code;
      switch (errCode) {
        case "auth/popup-closed-by-user":
          break;
        default:
          console.log("Google login error", errCode);
          break;
      }
    });
};

const Forgot = ({ onSignIn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isBusy, setIsBusy] = React.useState(false);
  const [isRequested, setIsRequested] = React.useState(false);

  const onSendReset = ({ email }) => {
    sendReset(email);
  };

  const sendReset = (email) => {
    setIsBusy(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // console.log("Reset email sent");
        setIsRequested(true);
      })
      .catch((error) => {
        const errCode = error.code;
        switch (errCode) {
          case "auth/too-many-requests":
            break;
          default:
            console.log("Error sending reset email", error);
            break;
        }
        setIsRequested(true);
      });
  };

  return (
    <div className={styles.loginBody}>
      <div className={styles.loginText}>Reset Password</div>
      <div className={styles.loginForm}>
        {isRequested && (
          <div style={{ textAlign: "center", paddingBottom: "1rem" }}>
            If an account with that email address exists we'll send a password
            reset email.
          </div>
        )}
        {!isRequested && (
          <form onSubmit={handleSubmit(onSendReset)}>
            <input
              type="email"
              id="email"
              name="email"
              autoFocus
              disabled={isRequested}
              {...register("email", {
                required: { value: true, message: "Email is required" },
                pattern: {
                  value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
                  message: "Valid email is required",
                },
              })}
              placeholder="Email address"
              className={`${styles.loginInput} ${
                errors.email ? styles.inputError : ""
              }`}
            />
            {errors.email && (
              <div style={{ fontSize: "0.75rem", color: "red" }}>
                {errors.email.message}
              </div>
            )}

            <button
              type="submit"
              className={`${styles.loginEmailButton} ${
                !isBusy ? "" : styles.disabledButton
              }`}
              disabled={isBusy}
            >
              Send Reset Email
            </button>
          </form>
        )}
        <div style={{ fontSize: "0.75rem", paddingTop: "0.5rem" }}>
          <button onClick={onSignIn} className={styles.linkButton}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

const Verify = ({ user, refreshUser, onVerified }) => {
  useSignals();
  const [canResend, setCanResend] = React.useState(false);
  const [timer, setTimer] = React.useState(60);

  const resendVerification = useCallback(() => {
    if (!user) return;
    if (user.value.emailVerified) {
      onVerified();
      return;
    }
    if (!auth.currentUser) return;

    const acs = {
      url: "https://www.maldivesguide.com/",
    };

    sendEmailVerification(auth.currentUser, acs)
      .then(() => {
        // console.log("Verification email sent");
        setTimer(60);
        setCanResend(false);
      })
      .catch((error) => {
        const errCode = error.code;
        switch (errCode) {
          case "auth/too-many-requests":
            break;
          default:
            console.log("Error sending verification email", error);
            break;
        }
      });
  }, [onVerified, user]);

  useEffect(() => {
    resendVerification();
  }, [resendVerification]);

  useEffect(() => {
    const canResendTimer = setInterval(async () => {
      if (timer < 0) {
        return;
      }
      if (timer > 0) {
        setTimer(timer - 1);
        if (timer % 10 === 0) {
          refreshUser();
        }
      } else {
        clearInterval(canResendTimer);
        setCanResend(true);
        refreshUser();
      }
    }, 1000);

    return () => clearInterval(canResendTimer);
  }, [timer, onVerified, refreshUser]);

  useEffect(() => {
    if (user.value.emailVerified) {
      setCanResend(false);
      setTimer(-1);
      onVerified();
    }
  }, [user?.value?.emailVerified, onVerified]);

  return (
    <div>
      <div className={styles.loginBody}>
        <div className={styles.loginText}>Verify your email</div>
        <div style={{ textAlign: "center", paddingBottom: "0.5rem" }}>
          A verification email has been sent to {user.value.email}
        </div>
        <button
          type="button"
          className={`${styles.loginEmailButton} ${
            canResend ? "" : styles.disabledButton
          }`}
          disabled={!canResend}
          onClick={resendVerification}
        >
          Resend Verification {canResend ? "" : `(${timer})`}
        </button>
      </div>
    </div>
  );
};

const SignIn = ({ message, onSignUp, onReset: onForgot }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [signinError, setSigninError] = React.useState(null);

  const onSignIn = async ({ email, password }) => {
    setIsBusy(true);
    setSigninError(null);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // console.log("Signin", userCredential);
        setIsBusy(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        setIsBusy(false);
        switch (errorCode) {
          case "auth/invalid-credential":
            setSigninError("Invalid email or password.");
            break;
          default:
            console.log(errorCode);
            setSigninError("An error occurred. Please try again later.");
            break;
        }
      });
  };

  return (
    <div className={styles.loginBody}>
      <div className={styles.loginText}>Sign In</div>
      {
        message && (
          <div className={styles.loginMessage}>
            {message}
          </div>
        )
      }
      <div className={styles.loginSocial}>
        <button onClick={signInGoogle} className={styles.loginButton}>
          <img src={glogo} alt="Google logo" className={styles.loginIcon} />
          <span>Continue with Google</span>
        </button>
      </div>
      <div className={styles.loginDivider}>
        <div className={styles.loginOr}>
          <span>or</span>
        </div>
      </div>
      <div className={styles.loginForm}>
        <form onSubmit={handleSubmit(onSignIn)}>
          <input
            type="email"
            id="email"
            // autoFocus
            {...register("email", {
              required: { value: true, message: "Email is required" },
              pattern: {
                value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
                message: "Valid email is required",
              },
            })}
            placeholder="Email address"
            className={`${styles.loginInput} ${
              errors.email ? styles.inputError : ""
            }`}
          />
          {errors.email && (
            <div style={{ fontSize: "0.75rem", color: "red" }}>
              {errors.email.message}
            </div>
          )}
          <div className={styles.iconedInput}>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              {...register("password", {
                required: { value: true, message: "Password is required" },
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              placeholder="Password"
              className={`${styles.loginInput} ${
                errors.password ? styles.inputError : ""
              }`}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className={styles.inputIconButton}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
          {errors.password && (
            <div style={{ fontSize: "0.75rem", color: "red" }}>
              {errors.password.message}
            </div>
          )}
          <button
            type="button"
            onClick={onForgot}
            className={styles.linkButton}
            style={{ fontSize: "0.75rem", marginTop: "5px" }}
          >
            Forgot password?
          </button>
          <button
            type="submit"
            className={`${styles.loginEmailButton} ${
              isBusy ? styles.disabledButton : ""
            }`}
            disabled={isBusy}
          >
            Sign In
          </button>
          {signinError && (
            <div
              style={{
                fontSize: "0.75rem",
                color: "red",
                paddingTop: "0.5rem",
              }}
            >
              {signinError}
            </div>
          )}
        </form>
      </div>
      <div style={{ fontSize: "0.75rem", paddingTop: "0.5rem" }}>
        New to Maldives Guide?{" "}
        <button type="button" onClick={onSignUp} className={styles.linkButton}>
          Join Now
        </button>
      </div>
    </div>
  );
};

const SignUp = ({ onSignIn }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [signupError, setSignupError] = React.useState(null);

  const onSignup = async ({ email, password }) => {
    setIsBusy(true);
    setSignupError(null);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // console.log("Create user email", userCredential);
        setIsBusy(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        // const errorMessage = error.message;
        setIsBusy(false);
        switch (errorCode) {
          case "auth/email-already-in-use":
            setSignupError(
              <div>
                Email already in use. Did you mean to
                <button onClick={onSignIn} className={styles.linkButton}>
                  Sign In?
                </button>
              </div>
            );
            break;

          default:
            console.log(errorCode);
            setSignupError("An error occurred. Please try again later.");
            break;
        }
      });
  };

  return (
    <div className={styles.loginBody}>
      <div className={styles.loginText}>Register</div>
      <div className={styles.loginForm}>
        <form onSubmit={handleSubmit(onSignup)}>
          <label htmlFor="email" className={styles.loginLabel}>
            Email address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            autoFocus
            {...register("email", {
              required: { value: true, message: "Email is required" },
              pattern: {
                value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
                message: "Valid email is required",
              },
            })}
            placeholder="Email address"
            className={`${styles.loginInput} ${
              errors.email ? styles.inputError : ""
            }`}
          />
          {errors.email && (
            <div style={{ fontSize: "0.75rem", color: "red" }}>
              {errors.email.message}
            </div>
          )}
          <label
            htmlFor="password"
            className={styles.loginLabel}
            style={{ marginTop: "0.5rem" }}
          >
            Password (6+ characters)
          </label>
          <div className={styles.iconedInput}>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              {...register("password", {
                required: { value: true, message: "Password is required" },
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              placeholder="Password"
              className={`${styles.loginInput} ${
                errors.password ? styles.inputError : ""
              }`}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className={styles.inputIconButton}
            >
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
          {errors.password && (
            <div style={{ fontSize: "0.75rem", color: "red" }}>
              {errors.password.message}
            </div>
          )}
          <button
            type="submit"
            className={`${styles.loginEmailButton} ${
              isBusy ? styles.disabledButton : ""
            }`}
            disabled={isBusy}
          >
            Register
          </button>
          {signupError && (
            <div
              style={{
                fontSize: "0.75rem",
                color: "red",
                paddingTop: "0.5rem",
              }}
            >
              {signupError}
            </div>
          )}
        </form>
      </div>
      <div className={styles.loginDivider}>
        <div className={styles.loginOr}>
          <span>or</span>
        </div>
      </div>
      <div className={styles.loginSocial}>
        <button onClick={signInGoogle} className={styles.loginButton}>
          <img src={glogo} alt="Google logo" className={styles.loginIcon} />
          <span>Continue with Google</span>
        </button>
      </div>
      <div style={{ fontSize: "0.75rem", paddingTop: "0.5rem" }}>
        Existing User?{" "}
        <button onClick={onSignIn} className={styles.linkButton}>
          Sign In
        </button>
      </div>
    </div>
  );
};

const Login = () => {
  useSignals();
  const userState = useContext(UserContext);

  useEffect(() => {
    if(userState?.user?.value?.emailVerified) {
      userState.loginView.value = "";
    }
  }, [userState?.user?.value]);

  return (
    <div>
      {userState?.loginView?.value && (
        <div className={styles.loginOverlay}>
          <div className={styles.loginContainer}>
            <div className={styles.loginClose}>
              <button
                onClick={() => (userState.loginView.value = "")}
                className={styles.closeButton}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            {/* <div className={styles.loginHeader}>
              <img
                src={logo}
                alt="Maldives Guide logo"
                style={{ width: "100%", height: "100%" }}
              />
            </div> */}
            {!userState?.user?.value && (
              <>
                {(!userState || userState?.loginView.value === "signup") && (
                  <SignUp
                    onSignIn={() => (userState.loginView.value = "signin")}
                  />
                )}
                {userState?.loginView.value === "signin" && (
                  <SignIn
                    message={userState.loginMessage}
                    onSignUp={() => (userState.loginView.value = "signup")}
                    onReset={() => (userState.loginView.value = "forgot")}
                  />
                )}
                {userState?.loginView.value === "forgot" && (
                  <Forgot
                    onSignIn={() => (userState.loginView.value = "signin")}
                  />
                )}
              </>
            )}
            {userState?.user?.value &&
              !userState?.user?.value?.emailVerified && (
                <Verify
                  user={userState.user}
                  refreshUser={userState.refreshUser}
                  onVerified={() => (userState.loginView.value = "")}
                />
              )}
            {userState?.user?.value &&
              userState?.user?.value?.emailVerified && (
                <div>Welcome {userState.user.value.email}</div>
              )}
          </div>
        </div>
      )}

    </div>
  );
};

Login.propTypes = {};

export default Login;
